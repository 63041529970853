<template>
  <div class="product-description-accordion">
    <div class="text-right space-x-4 mb-4">
      <button type="button" :class="['focus:outline-none', {'opacity-30 cursor-default': expanded}, {'text-white cursor-pointer': !expanded}]" @click="expandAll">Expand all</button>
      <span class="inline-block h-4 w-px bg-microbal-100"></span>
      <button type="button" :class="['focus:outline-none', {'opacity-30 cursor-default': !expanded}, {'text-white cursor-pointer': expanded}]" @click="collapseAll">Collapse all</button>
    </div>

    <div class="accordion-item transition transition-colors active" v-if="product.elements.wine_description.value">
      <div class="py-8 pr-14 pl-0 accordion-question relative h5 cursor-pointer transition transition-colors" @click="toggleItem">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.386" height="12.202" viewBox="0 0 22.386 12.202" class="stroke-current block absolute right-0 transition duration-300 top-8 mt-2">
          <g id="Action_Down_Arrow_Copy_2" data-name="Action_Down Arrow Copy 2" transform="translate(0.681 0.733)">
            <path id="Path_Copy_2" data-name="Path Copy 2" d="M0,0,10.85,10.083,21,0" fill="none" stroke="#9a9a9a" stroke-miterlimit="10" stroke-width="2"/>
          </g>
        </svg>
        Wine Description
      </div>
      <div class="pb-16 pt-4 px-0 accordion-answer transition transition-colors">
        <pre class="space-y-8" v-html="product.elements.wine_description.value"></pre>
      </div>
    </div>

    <div class="accordion-item transition transition-colors" v-if="product.elements.accolades.value">
      <div class="py-8 pr-14 pl-0 accordion-question relative h5 cursor-pointer transition transition-colors" @click="toggleItem">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.386" height="12.202" viewBox="0 0 22.386 12.202" class="stroke-current block absolute right-0 transition duration-300 top-8 mt-2">
          <g id="Action_Down_Arrow_Copy_2" data-name="Action_Down Arrow Copy 2" transform="translate(0.681 0.733)">
            <path id="Path_Copy_2" data-name="Path Copy 2" d="M0,0,10.85,10.083,21,0" fill="none" stroke="#9a9a9a" stroke-miterlimit="10" stroke-width="2"/>
          </g>
        </svg>
        Accolades
      </div>
      <div class="pb-16 pt-4 px-0 hidden accordion-answer transition transition-colors">
        <pre class="space-y-8" v-html="product.elements.accolades.value"></pre>
      </div>
    </div>

    <div class="accordion-item transition transition-colors" v-if="product.elements.winemaker.value">
      <div class="py-8 pr-14 pl-0 accordion-question relative h5 cursor-pointer transition transition-colors" @click="toggleItem">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.386" height="12.202" viewBox="0 0 22.386 12.202" class="stroke-current block absolute right-0 transition duration-300 top-8 mt-2">
          <g id="Action_Down_Arrow_Copy_2" data-name="Action_Down Arrow Copy 2" transform="translate(0.681 0.733)">
            <path id="Path_Copy_2" data-name="Path Copy 2" d="M0,0,10.85,10.083,21,0" fill="none" stroke="#9a9a9a" stroke-miterlimit="10" stroke-width="2"/>
          </g>
        </svg>
        Winemaker
      </div>
      <div class="pb-16 pt-4 px-0 hidden accordion-answer transition transition-colors">
        <pre class="space-y-8" v-html="product.elements.winemaker.value"></pre>
      </div>
    </div>

    <div class="accordion-item transition transition-colors" v-if="product.elements.winemaker_biography.value">
      <div class="py-8 pr-14 pl-0 accordion-question relative h5 cursor-pointer transition transition-colors" @click="toggleItem">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.386" height="12.202" viewBox="0 0 22.386 12.202" class="stroke-current block absolute right-0 transition duration-300 top-8 mt-2">
          <g id="Action_Down_Arrow_Copy_2" data-name="Action_Down Arrow Copy 2" transform="translate(0.681 0.733)">
            <path id="Path_Copy_2" data-name="Path Copy 2" d="M0,0,10.85,10.083,21,0" fill="none" stroke="#9a9a9a" stroke-miterlimit="10" stroke-width="2"/>
          </g>
        </svg>
        Winemaker's Bio
      </div>
      <div class="pb-16 pt-4 px-0 hidden accordion-answer transition transition-colors">
        <pre class="space-y-8" v-html="product.elements.winemaker_biography.value"></pre>
      </div>
    </div>

  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: 'ProductDescriptionAccordion',
  props: {
    product: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      expanded: false,
      itemCount: 0
    }
  },
  methods: {
    expandAll () {
      $(this.$el).find('.accordion-item').addClass('active')
      $(this.$el).find('.accordion-answer').slideDown()
      this.expanded = true
    },
    collapseAll () {
      $(this.$el).find('.accordion-item').removeClass('active')
      $(this.$el).find('.accordion-answer').slideUp()
      this.expanded = false
    },
    toggleItem (e) {
      if ($(e.target).parents('.accordion-item').hasClass('active')) {
        $(e.target).parents('.accordion-item').removeClass('active')
        $(e.target).parents('.accordion-item').find('.accordion-answer').slideUp()
      } else {
        $(e.target).parents('.accordion-item').addClass('active')
        $(e.target).parents('.accordion-item').find('.accordion-answer').slideDown()
      }
      if ($(e.target).parents('.winery-bio-accordion').find('.accordion-item').not('.active').length === this.itemCount) {
        this.expanded = false
      }
      if ($(e.target).parents('.winery-bio-accordion').find('.accordion-item.active').length === this.itemCount) {
        this.expanded = true
      }
    }
  },
  computed: {},
  mounted() {
    this.itemCount = $(this.$el).find('.accordion-item').length
  }
}
</script>

<style scoped lang="scss">
.accordion-item {
  border-top: 1px solid #323232;
}
.product-description-accordion {
  border-bottom: 1px solid #323232;
}
.accordion-item.active {
  svg {
    transform: rotate(180deg);
  }
}
.accordion-question {
  user-select: none;
  color: #9A9A9A;
}
pre {
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
  font-family: inherit !important;
}
</style>
