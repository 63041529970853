<template>
  <div class="tags -m-4 flex flex-row flex-wrap">
    <div class="tag p-4" v-for="(tag, index) in tags" :key="`tag${index}`">
      <div class="tag-inner px-4 py-2">{{ tag.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    tags: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tag-inner {
  border: 1px solid #323232;
}
</style>
