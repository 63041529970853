<template>
  <div class="other-wines">
    <div class="heading border-b border-grey-100 pb-8" v-if="heading">
      <div class="outer-container">
        <div class="inner-container flex flex-row justify-between items-start">
          <h4>{{heading}}</h4>
          <button v-if="viewAllUrl" class="cta text-14 flex flex-row items-center uppercase bg-grey-100 text-white py-4 px-6 hover:bg-grey-300 transition-colors" @click.prevent="viewAll">
            View all
            <img src="@/assets/icon-arrow.png" alt="" class="w-8 h-4 block ml-4">
          </button>
        </div>
      </div>
    </div>

    <div class="carousel">
      <div class="outer-container">
        <div class="inner-container">
          <swiper ref="carousel" :options="swiperOptions" class="relative pt-16 -mx-6">
            <swiper-slide v-for="(product, index) in products" :key="'product' + product.system.codename"  class="product-slide">
              <div class="p-6">
                <product-tile :product="product" :index="index"></product-tile>
              </div>
            </swiper-slide>
          </swiper>
          <div class="scrollbar swiper-scrollbar swiper-scrollbar-products h-2 mt-2 bg-grey-100"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ProductTile from "@/components/ProductTile";
export default {
  name: 'OtherWinesCarousel',
  props: {
    heading: {
      type: String,
      default: null
    },
    viewAllUrl: {
      type: String,
      default: null
    },
    products: {
      type: Array,
      default () {
        return []
      }
    }
  },
  components: {
    ProductTile,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        spaceBetween: 0,
        slidesPerView: 'auto',
        scrollbar: {
          el: '.swiper-scrollbar-products',
          hide: false,
          draggable: true
        },
        freeMode: true
      }
    }
  },
  methods: {
    viewAll () {
      this.$store.commit('setSearchView', 'products')
      this.$router.push(this.viewAllUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  font-size: 18px;
  font-weight: bold;
  @media(min-width: 768px)
  {
    font-size: 34px;
  }
}
.product-slide {
  // w-full sm:w-1/2 md:w-1/3 xl:w-1/4
  width: 75%;
  &:nth-child(even) {
    margin-top: -20px;
  }

  @media(min-width: 768px)
  {
    width: 42%;
  }

  @media(min-width: 1024px)
  {
    width: 30%;
  }

  @media(min-width: 1200px)
  {
    width: 23%;
  }
}
</style>

<style lang="scss">
.swiper-scrollbar-drag {
  @apply bg-white bg-opacity-20;
}
</style>
