<template>
  <div class="product">
    <div class="loading flex flex-row items-center justify-center fixed left-0 top-20 md:top-40 lg:top-60 w-full bottom-0 z-100 bg-black transition-all duration-500" :class="[{'invisible opacity-0': product}]">
      <loading-spinner />
    </div>
    <div class="page min-h-screen">
      <div v-if="product" :key="'product-detail_' + this.$route.params.product">
        <div class="outer-container">
          <div class="inner-container">
            <div class="breadcrumbs mt-12 text-grey-400 text-12 uppercase">
<!--              <router-link to="/expo" class="hover:text-white transition-colors">Expo</router-link><span class="divider inline-block px-4">&gt;</span>Products<span class="divider inline-block px-4">&gt;</span><router-link :to="`/expo/${$route.params.regionSlug}`" class="hover:text-white transition-colors">{{region.elements.name.value}}</router-link><span class="divider inline-block px-4">&gt;</span><span class="text-white">{{this.product.elements.name.value}}</span>-->
              <router-link to="/expo" class="hover:text-white transition-colors">Expo</router-link><span class="divider inline-block px-4">&gt;</span>Products<span class="divider inline-block px-4">&gt;</span><span class="text-white">{{this.product.elements.name.value}}</span>
            </div>
            <div class="hero mt-12 pb-16 md:pb-36 relative">
              <bleed-container bleed="both" class="hero-image-height">
                <div class="hero-image absolute right-0 top-0 w-7/10 h-7/10 lg:h-5/10 z-10 lg:w-1/2">
                  <img v-if="region && region.elements.hero_image.value.length" :data-lowsrc="`${region.elements.hero_image.value[0].url}?w=100&auto=format`" :data-srcset="`${region.elements.hero_image.value[0].url}?w=767&h=250&fit=crop&auto=format 767w, ${region.elements.hero_image.value[0].url}?w=1000&h=450&fit=crop&auto=format 1023w, ${region.elements.hero_image.value[0].url}?w=2000&auto=format 2000w`" alt="Winery Name" class="lazyload block absolute left-0 top-0 w-full h-full object-cover max-w-none">
                  <div v-if="isViewed" class="viewed absolute bg-white bg-opacity-60 flex flex-row items-center text-black right-8 top-8 p-4 py-2 text-12">
                    <svg id="icon_eye" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="9" viewBox="0 0 14 9" class="mr-2">
                      <defs>
                        <clipPath id="clip-path">
                          <path id="Clip_2" data-name="Clip 2" d="M0,0H13.868V8.844H0Z" fill="none"/>
                        </clipPath>
                      </defs>
                      <g id="Group_6" data-name="Group 6" transform="translate(0 0.15)">
                        <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
                          <path id="Clip_2-2" data-name="Clip 2" d="M0,0H13.868V8.844H0Z" transform="translate(0 0)" fill="none"/>
                          <g id="Group_3-2" data-name="Group 3" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <path id="Fill_1" data-name="Fill 1" d="M6.934,8.844C2.413,8.844,0,5.141,0,4.422S2.413,0,6.934,0s6.934,3.7,6.934,4.422S11.455,8.844,6.934,8.844ZM6.934.6A6.96,6.96,0,0,0,.615,4.425a2.318,2.318,0,0,0,.417.788A7.15,7.15,0,0,0,2.226,6.505,7.183,7.183,0,0,0,6.934,8.244a6.96,6.96,0,0,0,6.319-3.826,2.318,2.318,0,0,0-.417-.788,7.15,7.15,0,0,0-1.194-1.291A7.183,7.183,0,0,0,6.934.6Z" transform="translate(0 0)"/>
                          </g>
                        </g>
                        <path id="Fill_4" data-name="Fill 4" d="M2.612,4.956A2.551,2.551,0,0,1,0,2.478,2.551,2.551,0,0,1,2.612,0,2.551,2.551,0,0,1,5.225,2.478,2.551,2.551,0,0,1,2.612,4.956ZM2.612.6a1.942,1.942,0,0,0-2,1.878,1.943,1.943,0,0,0,2,1.878,1.943,1.943,0,0,0,2-1.878A1.942,1.942,0,0,0,2.612.6Z" transform="translate(4.32 1.947)"/>
                      </g>
                    </svg>
                    You've viewed this page
                  </div>
                </div>
                <div class="hero-bottle absolute left-0 top-0 w-full h-full z-20">
                  <div class="bg-grey-100 w-5/10 md:w-7/10 lg:w-6/10 absolute left-0 top-28 bottom-8">
                    <div class="favourite absolute z-50 top-8 right-8 hidden md:flex md:flex-row md:items-center cursor-pointer" @click="toggleFavourite">
                      <span v-if="!favouriteLoading">
                        <span v-if="isFavourite" class="text-primary-500">
                          <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                        </span>
                        <span v-if="!isFavourite">
                          <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path></svg>
                        </span>
                      </span>
                      <span v-else>
                        <favourite-loading-spinner />
                      </span>
                      <span class="ml-3">Favourite</span>
                    </div>
                  </div>
                  <div class="bleed-wrapper relative h-full">
                    <div class="bottle-container w-7/10 lg:w-6/10 pt-28 pb-8 h-full relative md:flex md:flex-row md:items-center">
                      <div class="bottle-shot absolute left-0 top-4 bottom-0 w-6/10 md:w-4/10">
<!--                        <div class="mep-badge" v-if="product.elements.mep && product.elements.mep.value.length && product.elements.mep.value[0].codename === 'yes'">-->
<!--                          <img src="@/assets/mep-badge-small.png" alt="">-->
<!--                        </div>-->
                        <img :key="'product-shot_' + this.$route.params.product" v-if="product.elements.bottle_shot.value.length" :src="`${product.elements.bottle_shot.value[0].url}?w=300`" class="block absolute left-0 top-0 w-full h-full object-contain max-w-none">
                      </div>
                      <div class="bottle-stuff hidden md:block md:pl-4/10">
                        <div class="bottle-stuff-inner pl-4 pr-12 lg:pl-8 lg:pr-8">
                          <div class="brand uppercase text-primary-500 font-bold">{{ product.elements.brand___range_name.value }}</div>
<!--                          <router-link v-if="region" :to="`/expo/wineries/${region.elements.slug.value}`" class="brand uppercase text-primary-500 font-bold">{{ product.elements.brand___range_name.value }}</router-link>-->
                          <h1 class="title">{{ product.elements.name.value }}</h1>
                          <div class="region mt-4 opacity-50 pr-8"><span v-if="product.elements.label_claim.value">{{product.elements.label_claim.value}}</span></div>
                        </div>
                      </div>
                    </div>
                    <div class="logo-box bg-black p-6 md:p-16 lg:p-20 absolute right-0 -bottom-16 md:-bottom-36" v-if="region && region.elements.region_logo.value.length">
                      <div class="represented mb-4">Represented by</div>
                      <router-link :to="`/discover/${stateSlug}/associated/${region.elements.slug.value}`" class="block logo relative p-4 lg:p-8 w-52 h-52 md:w-64 md:h-64 lg:w-100 lg:h-100 bg-grey-100">
                        <div class="logo-img absolute left-0 top-0 right-0 bottom-0">
                          <img v-if="region.elements.region_logo.value.length" :src="region.elements.region_logo.value[0].url" :alt="product.elements.brand___range_name.value" class="absolute object-contain object-center left-0 top-0 w-full h-full">
                        </div>
                      </router-link>
                    </div>
                  </div>

                </div>
              </bleed-container>
            </div>

<!--            <router-link v-if="region" :to="`/expo/wineries/${region.elements.slug.value}`" class="md:hidden mt-8 brand uppercase text-primary-500 font-bold">{{ product.elements.brand___range_name.value }}</router-link>-->
            <h1 class="md:hidden title mt-4">{{ product.elements.name.value }}</h1>
            <div class="md:hidden region mt-4 opacity-50 pr-8"><span v-if="product.elements.label_claim.value">{{product.elements.label_claim.value}}</span></div>

            <div class="main-content relative mt-24 md:mt-12">
              <div class="row md:flex md:flex-row md:items-start">
                <div class="main flex-grow">

                  <div class="top relative">
                    <div class="favourite md:hidden absolute -top-12 md:-top-16 lg:top-0 lg:right-0 flex flex-row items-center cursor-pointer" @click="toggleFavourite">
                      <span v-if="!favouriteLoading">
                        <span v-if="isFavourite" class="text-primary-500">
                          <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                        </span>
                        <span v-if="!isFavourite">
                          <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path></svg>
                        </span>
                      </span>
                      <span v-else>
                        <favourite-loading-spinner />
                      </span>
                      <span class="ml-3">Favourite</span>
                    </div>
                    <div class="top-container">
                      <div class="stats mt-12 flex flex-row flex-wrap -m-4">
                        <div class="stat p-4 md:mr-12 lg:mr-24" v-if="product.elements.vintage.value">
                          <div class="stat-label text-12 font-semibold text-grey-500">Vintage</div>
                          <div class="stat-value text-22 md:text-34 leading-1">{{ product.elements.vintage.value }}</div>
                        </div>
                        <div class="stat p-4 md:mr-12 lg:mr-24" v-if="product.elements.abv__.value">
                          <div class="stat-label text-12 font-semibold text-grey-500">ABV</div>
                          <div class="stat-value text-22 md:text-34 leading-1">{{ product.elements.abv__.value }}%</div>
                        </div>
                        <div class="stat p-4 md:mr-12 lg:mr-24" v-if="product.elements.price_point.value.length">
                          <div class="stat-label text-12 font-semibold text-grey-500">AUD$ FOB / 9L Case</div>
                          <div class="stat-value text-22 md:text-34 leading-1">{{ product.elements.price_point.value[0].name }}</div>
                        </div>
                        <div class="stat p-4 md:mr-12 lg:mr-24" v-if="product.elements.wine_type.value">
                          <div class="stat-label text-12 font-semibold text-grey-500">Wine Type</div>
                          <div class="stat-value text-22 md:text-34 leading-1">{{product.elements.wine_type.value}}</div>
                        </div>
                        <div class="stat p-4 md:mr-12 lg:mr-24" v-if="product.elements.wine_style.value">
                          <div class="stat-label text-12 font-semibold text-grey-500">Wine Style</div>
                          <div class="stat-value text-22 md:text-34 leading-1">{{ product.elements.wine_style.value }}</div>
                        </div>
                      </div>
                      <tags class="mt-12" :tags="product.elements.properties.value" v-if="product.elements.properties.value.length" />
                    </div>
                  </div>
                  <product-description-accordion class="mt-16" :product="product" />
                  <downloads class="mt-16" :files="uniqueDocs" :product-id="this.product.elements.product_id.value" :region-id="this.region.elements.region_id.value" />
                  <product-notes class="mt-16" :product-id="this.product.elements.product_id.value ? this.product.elements.product_id.value : '0'" heading="Your ratings and notes" intro="Rate and make any notes about this product to save in your profile. Your rating and comments will only be seen by you." />
                </div>
                <aside class="hidden md:block md:sticky md:top-40 lg:top-60 md:w-96 lg:w-380 flex-shrink-0 md:ml-16 lg:ml-24 xl:ml-56" v-if="region">

                  <bleed-container bleed="right">
                    <div class="bg-grey-100 p-8 lg:p-16">
                      <div class="bleed-wrapper">
                        <region-share :region="region" />
                      </div>
                    </div>
                  </bleed-container>
                </aside>
              </div>
            </div>
          </div>
        </div>

        <other-wines-carousel v-if="products && products.length" class="mt-16" :heading="`Other wines from ${region.elements.name.value}`" :products="products" />

        <curated-collections v-if="collections.length" :collections="collections" :modular-content="collectionsContent" :show-spotlight="false" heading="Featured in collections —" />

        <div class="outer-container md:hidden" v-if="region">
          <div class="inner-container">
            <div class="bg-grey-100 p-8 lg:p-16 mt-16">
              <region-share :region="region" />
            </div>
          </div>
        </div>

        <region-connect :region="region" v-if="region" :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import BleedContainer from "@/components/BleedContainer";
import ProductDescriptionAccordion from "@/components/ProductDescriptionAccordion";
import Tags from "@/components/Tags";
import OtherWinesCarousel from "@/components/OtherWinesCarousel";
import LoadingSpinner from "@/components/LoadingSpinner";
import Kontent from "@/kontent";
import ProductNotes from "@/components/ProductNotes";
import FavouriteLoadingSpinner from "@/components/FavouriteLoadingSpinner";
import Bus from "@/events/EventBus";
import CuratedCollections from "@/components/CuratedCollections";
import RegionConnect from "@/components/RegionConnect";
import RegionShare from "@/components/RegionShare";
import Downloads from "@/components/Downloads";
export default {
  name: 'Product',
  components: {
    Downloads,
    RegionShare,
    RegionConnect,
    CuratedCollections,
    FavouriteLoadingSpinner,
    ProductNotes,
    LoadingSpinner,
    OtherWinesCarousel, Tags, ProductDescriptionAccordion, BleedContainer},
  props: {},
  data () {
    return {
      region: null,
      product: null,
      products: [],
      favouriteLoading: false,
      collections: [],
      collectionsContent: {}
    }
  },
  mounted () {
    this.getData()
    Bus.$on('favourite-products', () => {
      this.favouriteLoading = false
    })
  },
  methods: {
    toggleFavourite () {
        if (this.$auth.isAuthenticated) {
            this.favouriteLoading = true
            if (this.isFavourite) {
                this.$store.dispatch('removeFavouriteProduct', this.product.elements.product_id.value ? this.product.elements.product_id.value : '0')
            } else {
                this.$store.dispatch('addFavouriteProduct', this.product.elements.product_id.value ? this.product.elements.product_id.value : '0')
            }
        } else {
            this.showPopup()
        }

    },
    getData () {
      // get product
      Kontent.getItem('regional_product', this.$route.params.product, 1).then((res) => {
        this.product = res.data.items[0]
        Analytics.trackPage('Product: ' + this.product.elements.name.value)
        this.$store.dispatch("getFavouriteProducts")
        this.$store.dispatch("getViewedProducts")

        this.region = res.data.modular_content[res.data.items[0].elements.region.value]
        // get products by region
        Kontent.getAssociatedProducts(this.region.system.codename).then((res) => {
          const mappedProducts = res.data.items.map((item) => {
            return {
              ...item,
              elements: {
                ...item.elements,
                region_slug: {
                  value: item.elements.region.value.length ? res.data.modular_content[item.elements.region.value[0]].elements.slug.value : 'missing-slug'
                }
              }
            }
          })

          for (let i = 0; i < mappedProducts.length; i++) {
            const mappedProduct = mappedProducts[i];
            if (mappedProduct.elements.slug.value !== this.$route.params.product) {
              this.products.push(mappedProduct)
            }
          }
        })

        // get collections
        Kontent.getProductCollections(this.product.system.codename).then((res) => {
          this.collections = res.data.items
          this.collectionsContent = res.data.modular_content
        })
      })
    },
      showPopup() {
          Bus.$emit('show-locked-content-popup')
      }
  },
  computed: {
    isFavourite () {
      return this.$store.state.favouriteProducts.includes(this.product.elements.product_id.value ? this.product.elements.product_id.value : '0')
    },
    isViewed () {
      return this.$store.state.viewedProducts.includes(this.product.elements.product_id.value ? this.product.elements.product_id.value : '0')
    },
    // favouriteLoading () {
    //   // return this.$store.state.favouriteProductsLoading
    //   return false
    // },
    uniqueDocs () {
      return this.product.elements.technical_details.value.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)
    },
    stateSlug () {
      switch (this.region.elements.state.value) {
        case 'New South Wales':
          return 'new-south-wales'
        case 'Queensland':
          return 'queensland'
        case 'South Australia':
          return 'south-australia'
        case 'Western Australia':
          return 'western-australia'
        case 'Tasmania':
          return 'tasmania'
        case 'Victoria':
          return 'victoria'
      }
      return null
    }
  },
  beforeDestroy() {
    this.$store.dispatch("addViewedProduct", this.product.elements.product_id.value ? this.product.elements.product_id.value : '0')
    Bus.$off('favourite-products')
  },
  watch:{
    $route () {
      this.$store.dispatch("addViewedProduct", this.product.elements.product_id.value ? this.product.elements.product_id.value : '0')
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
.hero-image-height {
  height: 250px;

  @media(min-width: 768px)
  {
    height: 450px;
  }

  @media(min-width: 1024px)
  {
    height: 600px;
  }
}

h1 {
  font-size: 30px;

  @media(min-width: 1024px)
  {
    font-size: 40px;
  }

  @media(min-width: 1200px)
  {
    font-size: 47px;
  }
}
.mep-badge {
  position: absolute;
  right: 50%;
  margin-right: -60px;
  top: 50px;
  z-index: 5;
  img {
    width: 50px;
    height: 50px;
    display: block;
  }

  @media(min-width: 768px)
  {
    top: 100px;
    right: 10px;
    margin-right: 0;
    img {
      width: 90px;
      height: 90px;
    }
  }
}
</style>
