<template>
  <div class="curated-collections">
    <div class="heading border-b border-grey-100 py-4">
      <div class="outer-container">
        <div class="inner-container">
          <img src="@/assets/in-the-spotlight.png" alt="" v-if="showSpotlight">
          <div class="flex flex-row justify-between items-start mt-8 md:mt-16">
            <div class="section-heading">{{heading}}</div>
            <router-link to="/discover/collections" v-if="showAll" class="cta text-14 flex flex-row items-center uppercase bg-grey-100 text-white py-4 px-6 hover:bg-grey-300 transition-colors">
              View all
              <img src="@/assets/icon-arrow.png" alt="" class="w-8 h-4 block ml-4">
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="outer-container">
      <div class="inner-container">
        <div class="intro-text mt-8" v-if="intro" v-html="intro"></div>

        <div class="collection-list -mx-4 mt-24">
          <masonry
              :cols="{default: 3, 1023: 2, 767: 1}"
              :gutter="{default: '30px'}"
          >
            <div class="collection-tile w-full p-4" v-for="(item, index) in collections" :key="`collection${index}}`">
              <router-link :to="`/discover/collections/${item.elements.slug.value}`" class="block text-white winery-inner bg-grey-100 pl-12 pb-12 lg:pl-20 lg:pb-20 group">
                <div class="image">
                  <div class="image-container relative pb-9/16">
                    <img :src="item.elements.image.value[0].url" :alt="item.elements.name.value" class="block absolute left-0 top-0 w-full h-full object-cover max-w-none">
                  </div>
                </div>
                <div class="title mt-12 text-18 font-bold pr-8">{{item.elements.name.value}}</div>
                <div class="description mt-4 text-14 pr-8 text-grey-500" v-html="item.elements.short_description.value"></div>
                <div class="cta mt-12 text-14 flex flex-row items-center text-grey-400 uppercase group-hover:text-grey-500 transition-colors">
                  View collection
                  <img src="@/assets/icon-arrow.png" alt="" class="w-8 h-4 block ml-4 group-hover:ml-6 transition-all">
                </div>
              </router-link>
            </div>
          </masonry>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry);
export default {
  name: 'CuratedCollections',
  props: {
    collections: {
      type: Array,
      default () {
        return []
      }
    },
    modularContent: {
      type: Object,
      default () {
        return null
      }
    },
    heading: {
      type: String,
      default: null
    },
    intro: {
      type: String,
      default: null
    },
    showSpotlight: {
      type: Boolean,
      default: true
    },
    showAll: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.collection-list > div > div:nth-child(even) {
  margin-top: -30px;
}
</style>

<style scoped lang="scss">

.intro-text {
  max-width: 470px;
}
.section-heading {
  font-weight: 600;
  font-size: 24px;

  @media(min-width: 768px)
  {
    font-size: 34px;
  }
}

.collection-tile {
  margin-top: 30px;
}
</style>
