<template>
  <div class="notes">
    <h4 v-if="heading">{{heading}}</h4>
    <p class="mt-8" v-if="intro">{{intro}}</p>
    <div class="ratings mt-12 flex flex-row items-center">
      <star-rating
          :star-size="20"
          @rating-selected ="setRating"
          v-model="rating"
          :show-rating="false"
          inactive-color="#9A9A9A"
          border-color="#9A9A9A"
          active-color="#E8490F"
          active-border-color="#E8490F"
          :clearable="true"
          class="-mt-2"
      />
      <span class="ml-4" v-if="rating">You rated this {{rating}} star<span v-if="rating > 1">s</span></span>
    </div>
    <form class="mt-8" @submit.prevent="saveNotes">
      <textarea class="input-textarea" v-model="notes" />
      <div class="mt-4 text-right">
        <button type="submit" class="btn-transparent" :class="{'disabled': notesLoading}">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/api'
import StarRating from 'vue-star-rating'
export default {
  name: 'ProductNotes',
  components: {
    StarRating
  },
  props: {
    heading: {
      type: String,
      default: null
    },
    intro: {
      type: String,
      default: null
    },
    productId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      notes: '',
      notesLoading: false,
      rating: 0
    }
  },
  methods: {
    getNotes () {
      this.notesLoading = true
      api.getProductNotes(this.productId).then((res) => {
        this.notes = res.data.notes
        this.notesLoading = false
      })
    },
    saveNotes () {
      this.notesLoading = true
      api.addProductNotes(this.productId, this.notes).then(() => {
        this.notesLoading = false
        this.$toasted.show("Saved notes", {
          theme: "toasted-primary",
          position: "top-right",
          duration : 5000
        });
      })
    },
    setRating: function(rating){
      this.ratingLoading = true
      api.setProductRating(this.productId, rating).then(() => {
        this.rating = rating
        this.ratingLoading = false
        this.$toasted.show("Saved rating", {
          theme: "toasted-primary",
          position: "top-right",
          duration : 5000
        });
      })
    },
    getRating () {
      this.ratingLoading = true
      api.getProductRating(this.productId).then((res) => {
        this.rating = res.data.rating
        this.ratingLoading = false
      })
    }
  },
  mounted() {
    this.getNotes()
    this.getRating()
  }
}
</script>

<style scoped lang="scss">

</style>
